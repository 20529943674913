@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
body.main {
  font-family: "Microsoft YaHei", Arial, sans-serif;
  max-height: 100%;
  padding-top: 4rem;
}
body.main::-webkit-scrollbar,
body.main::-webkit-scrollbar-thumb {
  background-color: transparent;
  width: 0.3em;
}
body.main:hover::-webkit-scrollbar-thumb {
  background-color: #66CCFF;
  border-radius: 0.3em;
  outline: 2px solid slategrey;
}
body.main .toolbox > i {
  cursor: pointer;
}
body.main #dashboard {
  position: fixed;
  bottom: 5%;
  right: 5%;
  opacity: 0.5;
  z-index: 50;
  background-color: #eeeeee;
  border-radius: 0.5em;
}
body.main #dashboard:hover {
  opacity: 1;
}
body.main #dashboard i {
  display: inline-block;
  padding: 0.25em;
  border-right: 1px solid #ccc;
}
body.main #dashboard i:last-child {
  border-right: none;
}
body.main #dashboard i.success {
  color: #5cb85c;
}
body.main #dashboard i.filter-on {
  color: coral;
}
body.main #dashboard i.error {
  color: #f0ad4e;
  font-weight: bold;
}
body.main #dashboard.strong-notify {
  opacity: 1;
  transform: scale(2);
  transition: all 1s ease-out;
}
body.main #calendar-wrapper table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  box-sizing: border-box;
}
body.main #calendar-wrapper table th,
body.main #calendar-wrapper table td {
  max-width: 14.2857%;
  word-wrap: break-word;
  overflow-x: hidden;
  padding: 0;
  border: 1px solid black;
  background-color: whitesmoke;
  box-sizing: border-box;
}
body.main #calendar-wrapper #calendar-weekday-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1020;
}
body.main #calendar-wrapper #calendar-weekday-header th {
  height: 4rem;
  font-size: 2rem;
  line-height: 2rem;
  text-align: center;
  background-color: #dddddd;
}
body.main #calendar-wrapper #calendar-weekday-header th.weekend {
  color: #dd0000;
}
body.main #calendar-wrapper #calendar-container span.toolbox {
  display: none;
  position: absolute;
  max-width: 100%;
  background: transparent;
  min-height: 2rem;
  bottom: 0.25em;
  right: 0.25em;
}
body.main #calendar-wrapper #calendar-container td {
  height: 8vw;
}
body.main #calendar-wrapper #calendar-container td p.calendar-header {
  position: relative;
  padding: 0.5em;
  margin: 0;
}
body.main #calendar-wrapper #calendar-container td p.calendar-header span.calendar-year:after {
  content: '年';
}
body.main #calendar-wrapper #calendar-container td p.calendar-header span.calendar-month:after {
  content: '月';
}
body.main #calendar-wrapper #calendar-container td p.calendar-header span.calendar-day:after {
  content: '日';
}
body.main #calendar-wrapper #calendar-container td p.calendar-header span.calendar-week {
  display: none;
}
body.main #calendar-wrapper #calendar-container td p.calendar-header:not([data-month-day="0101"]) .calendar-year {
  display: none;
}
body.main #calendar-wrapper #calendar-container td p.calendar-header span.calendar-comment {
  margin-left: 0.5em;
  font-style: italic;
}
body.main #calendar-wrapper #calendar-container td p.calendar-header span.badge {
  margin-top: -5px;
}
body.main #calendar-wrapper #calendar-container td.month-odd {
  background-color: #e5e5e5;
}
body.main #calendar-wrapper #calendar-container td.elapsed p.calendar-header {
  text-decoration: line-through;
}
body.main #calendar-wrapper #calendar-container td.today p.calendar-header {
  font-weight: bold;
}
body.main #calendar-wrapper #calendar-container td:hover p.calendar-header span.toolbox {
  display: block;
}
body.main #calendar-wrapper #calendar-container td ol.agenda-container {
  list-style: none;
  padding: 0;
  margin: 0;
  min-height: calc(100% - 2.5em);
}
body.main #calendar-wrapper #calendar-container td ol.agenda-container li {
  padding-left: 0.25em;
  text-align: center;
  position: relative;
}
body.main #calendar-wrapper #calendar-container td ol.agenda-container li span.toolbox {
  top: 1.2em;
  left: 0;
  bottom: unset;
  white-space: nowrap;
  z-index: 9999;
}
body.main #calendar-wrapper #calendar-container td ol.agenda-container li span.toolbox i {
  padding: 0.1em;
  border: 1px solid #ccc;
  background-color: whitesmoke;
}
body.main #calendar-wrapper #calendar-container td ol.agenda-container li span.toolbox i:first-child {
  border-top-left-radius: 0.2em;
  border-bottom-left-radius: 0.2em;
}
body.main #calendar-wrapper #calendar-container td ol.agenda-container li span.toolbox i:last-child {
  border-top-right-radius: 0.2em;
  border-bottom-right-radius: 0.2em;
}
body.main #calendar-wrapper #calendar-container td ol.agenda-container li:last-child span.toolbox {
  top: -1.2em;
}
body.main #calendar-wrapper #calendar-container td ol.agenda-container li div.agenda-info {
  display: inline-block;
}
body.main #calendar-wrapper #calendar-container td ol.agenda-container li div.agenda-info span.agenda-name {
  margin: 0 0.25em;
  padding: 0 0.2em;
  border-radius: 0.25em;
}
body.main #calendar-wrapper #calendar-container td ol.agenda-container li ul.agenda-assignments {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
}
body.main #calendar-wrapper #calendar-container td ol.agenda-container li ul.agenda-assignments li {
  display: inline-block;
  position: relative;
}
body.main #calendar-wrapper #calendar-container td ol.agenda-container li ul.agenda-assignments li:before {
  content: "[";
}
body.main #calendar-wrapper #calendar-container td ol.agenda-container li ul.agenda-assignments li:after {
  content: "]";
}
body.main #calendar-wrapper #calendar-container td ol.agenda-container li ul.agenda-assignments li span.toolbox {
  top: 0;
  left: -70%;
}
body.main #calendar-wrapper #calendar-container td ol.agenda-container li ul.agenda-assignments li:hover > span.toolbox {
  display: block;
}
body.main #calendar-wrapper #calendar-container td ol.agenda-container li.active div.agenda-info {
  outline: 1px solid coral;
}
body.main #calendar-wrapper #calendar-container td ol.agenda-container li.active span.agenda-toolbox {
  display: block;
}
body.main #calendar-wrapper #calendar-container td ol.agenda-container li.new span.toolbox i {
  display: none;
}
body.main #calendar-wrapper #calendar-container td ol.agenda-container li.new span.toolbox i.fa-trash {
  display: inline-block;
}
body.main #calendar-wrapper #calendar-container td ol.agenda-container li[data-tag=normal] span.toolbox i.fa-flag-o,
body.main #calendar-wrapper #calendar-container td ol.agenda-container li[data-tag=done] span.toolbox i.fa-check,
body.main #calendar-wrapper #calendar-container td ol.agenda-container li[data-tag=postpone] span.toolbox i.fa-level-down,
body.main #calendar-wrapper #calendar-container td ol.agenda-container li[data-tag=removed] span.toolbox i.fa-times-circle {
  display: none;
}
body.main #calendar-wrapper #calendar-container td ol.agenda-container li .fa-arrows {
  visibility: hidden;
}
body.main #calendar-wrapper #calendar-container td ol.agenda-container li:hover .fa-arrows {
  visibility: visible;
}
@media (max-width: 991px) {
  body.main #calendar-wrapper #calendar-container td {
    display: block;
    max-width: unset;
    height: auto;
  }
  body.main #calendar-wrapper #calendar-container td p.calendar-header span.calendar-week {
    display: inline;
  }
}
body.main #calendar-wrapper #color-control {
  visibility: hidden;
  height: 1px;
  width: 1px;
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1900;
}
body.main #users-panel {
  display: none;
  position: fixed;
  margin: auto;
  top: 15vh;
  left: 0;
  right: 0;
  max-width: 20%;
  z-index: 100000;
}
body.main #users-panel button {
  text-align: center;
  white-space: nowrap;
}
body.main #users-panel button.cancel {
  color: grey;
}
body.main #users-panel button.everyone {
  font-weight: bold;
}
@media print {
  body.main #calendar-weekday-header {
    display: none;
  }
}
@media (max-width: 991px) {
  body.main #calendar-weekday-header {
    display: none;
  }
}
body.main .no-admin .toolbox i.fa-user-plus,
body.main .no-admin .toolbox i.fa-trash,
body.main .no-admin .toolbox i.fa-user-times {
  display: none;
}
body.main #modal-loading div.loading-content {
  width: 15em;
  height: 4rem;
  background-color: #f0ad4e;
  font-weight: bold;
  opacity: 0.8;
  padding: 1em;
  text-align: center;
  border-radius: 1em;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1080;
}
.user-avatar {
  position: relative;
}
.user-avatar i.fa-check {
  position: absolute;
  display: none;
  z-index: 9000;
}
.user-avatar.active i.fa-user {
  opacity: 0.5;
}
.user-avatar.active i.fa-check {
  display: inline-block;
  color: #DD127B;
}
