@import (reference) "../../../bower_components/bootstrap/less/bootstrap";

@weekday-header-height: 4rem;
@toolbox-border-radius: 0.2em;
@calendar-initial-height: 11vw;
body.main{
  font-family: "Microsoft YaHei", Arial, sans-serif;
  max-height: 100%;
  padding-top: @weekday-header-height;
  &::-webkit-scrollbar, &::-webkit-scrollbar-thumb{
    background-color: transparent;
    width: 0.3em;
  }
  &:hover{
    &::-webkit-scrollbar-thumb {
      background-color: #66CCFF;
      border-radius: 0.3em;
      outline: 2px solid slategrey;
    }
  }
  .toolbox > i{
    cursor: pointer;
  }
  #dashboard{
    position: fixed;
    bottom: 5%;
    right: 5%;
    opacity: 0.5;
    z-index: 50;
    background-color: @gray-lighter;
    border-radius: 0.5em;
    &:hover{
      opacity: 1;
    }
    i{
      display: inline-block;
      padding: 0.25em;
      border-right: 1px solid @btn-default-border;
      &:last-child{
        border-right: none;
      }
      &.success{
        color: @brand-success;
      }
      &.filter-on{
        color: coral;
      }
      &.error{
        color: @brand-warning;
        font-weight: bold;
      }
    }
    &.strong-notify{
      opacity: 1;
      transform: scale(2);
      transition: all 1s ease-out;
    }
  }
  #calendar-wrapper{
    table{
      width: 100%;
      border-collapse: collapse;
      table-layout: fixed;
      box-sizing: border-box;
      th, td{
        max-width: 14.2857%;
        word-wrap: break-word;
        overflow-x: hidden;
        padding: 0;
        border: 1px solid black;
        background-color: whitesmoke;
        box-sizing: border-box;
      }
    }
    #calendar-weekday-header{
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1020;
      th{
        height: @weekday-header-height;
        font-size: 2rem;
        line-height: 2rem;
        text-align: center;
        background-color: #dddddd;
        &.weekend{
          color: #dd0000;
        }
      }
    }
    #calendar-container{
      span.toolbox{
        display: none;
        position: absolute;
        max-width: 100%;
        background: transparent;
        min-height: 2rem;
        bottom: 0.25em;
        right: 0.25em;
      }
      td{ // Calendar
        height: 8vw;
        p.calendar-header{
          position: relative;
          padding: 0.5em;
          margin: 0;
          span.calendar-year:after{
            content: '年';
          }
          span.calendar-month:after{
            content: '月';
          }
          span.calendar-day:after{
            content: '日';
          }
          span.calendar-week{
            display: none;
          }
          &:not([data-month-day="0101"]) .calendar-year{
            display: none;
          }
          span.calendar-comment{
            margin-left: 0.5em;
            font-style: italic;
          }
          span.badge{
            margin-top: -5px;
          }
        }
        &.month-odd{
          background-color: @legend-border-color;
        }
        &.elapsed p.calendar-header{
          text-decoration: line-through;
        }
        &.today p.calendar-header{
          font-weight: bold;
        }
        &:hover p.calendar-header span.toolbox{
          display: block;
        }
        ol.agenda-container{
          list-style: none;
          padding: 0;
          margin: 0;
          min-height: ~"calc(100% - 2.5em)";
          li{
            padding-left: 0.25em;
            text-align: center;
            position: relative;
            span.toolbox{
              top: 1.2em;
              left: 0;
              bottom: unset;
              white-space: nowrap;
              z-index: 9999;
              i{
                padding: 0.1em;
                border: 1px solid @input-border;
                background-color: whitesmoke;
                &:first-child{
                  border-top-left-radius: @toolbox-border-radius;
                  border-bottom-left-radius: @toolbox-border-radius;
                }
                &:last-child{
                  border-top-right-radius: @toolbox-border-radius;
                  border-bottom-right-radius: @toolbox-border-radius;
                }
              }
            }
            &:last-child span.toolbox{
              top: -1.2em;
            }
            div.agenda-info{
              display: inline-block;
              span.agenda-name{
                margin: 0 0.25em;
                padding: 0 0.2em;
                border-radius: 0.25em;
              }
            }
            ul.agenda-assignments{
              list-style: none;
              padding: 0;
              margin: 0;
              display: inline-block;
              li{
                display: inline-block;
                position: relative;
                &:before{
                  content: "[";
                }
                &:after{
                  content: "]";
                }
                span.toolbox{
                  top: 0;
                  left: -70%;
                }
                &:hover > span.toolbox{
                  display: block;
                }
              }
            }
            &.active{
              div.agenda-info{
                outline: 1px solid coral;
              }
              span.agenda-toolbox{
                display: block;
              }
            }
            &.new{
              span.toolbox i{
                display: none;
              }
              span.toolbox i.fa-trash{
                display: inline-block;
              }
            }
            &[data-tag=normal] span.toolbox i.fa-flag-o, &[data-tag=done] span.toolbox i.fa-check, &[data-tag=postpone] span.toolbox i.fa-level-down, &[data-tag=removed] span.toolbox i.fa-times-circle{
              display: none;
            }
            .fa-arrows{
              visibility: hidden;
            }
            &:hover{
              .fa-arrows{
                visibility: visible;
              }
            }
          }
        }
      }
      @media (max-width: @screen-sm-max){
        td{
          display: block;
          max-width: unset;
          height: auto;
          p.calendar-header{
            span.calendar-week{
              display: inline;
            }
          }
        }
      }
    }
    #color-control{
      visibility: hidden;
      height: 1px;
      width: 1px;
      position: fixed;
      top: 50% !important;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1900;
    }
  }
  #users-panel{
    display: none;
    position: fixed;
    margin: auto;
    top: 15vh;
    left: 0;
    right: 0;
    max-width: 20%;
    z-index: 100000;
    button{
      text-align: center;
      white-space: nowrap;
      &.cancel{
        color: grey;
      }
      &.everyone{
        font-weight: bold;
      }
    }
  }
  @media print {
    #calendar-weekday-header{
      display: none;
    }
  }
  @media (max-width: @screen-sm-max){
    #calendar-weekday-header{
      display: none;
    }
  }
  .no-admin{
    .toolbox{
      i.fa-user-plus, i.fa-trash, i.fa-user-times{
        display: none;
      }
    }
  }
  #modal-loading{
    div.loading-content{
      width: 15em;
      height: 4rem;
      background-color: #f0ad4e;
      font-weight: bold;
      opacity: 0.8;
      padding: 1em;
      text-align: center;
      border-radius: 1em;
      margin: auto;
      position: absolute;
      top: 0; left: 0; bottom: 0; right: 0;
      z-index: 1080;
    }
  }
}
.user-avatar{
  position: relative;
  i.fa-check{
    position: absolute;
    display: none;
    z-index: 9000;
  }
  &.active{
    i.fa-user{
      opacity: 0.5;
    }
    i.fa-check{
      display: inline-block;
      color: #DD127B;
    }
  }
}
